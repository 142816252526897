<template>
  <b-card>
      <b-row v-show="contracts.length > 0 || search != null">
        <b-col md="7">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Exibir"
                  label-for="h-exibir"
                  label-cols-md="4"
                  class="text-muted"
                >
                  <b-form-select
                    size="sm"
                    class="form-content"
                    v-model="perPage"
                    :options="perPageOptions"
                    :disabled="isLoading"
                  />
                </b-form-group>
              </b-col>
            </b-row>
        </b-col>
        <b-col md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Pesquisar"
               v-model="search"
              debounce="800"
              type="search"
              size="sm"
              :disabled="isLoading"
            />
          </b-input-group>
        </b-col>
        <b-col md="2">
          <b-button block variant="relief-primary" @click="addNew" v-b-toggle.sidebar-create size="sm">
            Nova Assinatura
            <feather-icon icon="BoxIcon" />
          </b-button>
        </b-col>
      </b-row>
      <div v-show="contracts.length > 0 || search != null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
      <b-table-simple
        striped
        responsive
        class="mt-2"
        id="tableContrats"
      >
        <b-thead>
          <b-tr class="text-center">
            <b-th>Empresa</b-th>
            <b-th>Data de cadastro</b-th>
            <b-th>Data de expiração</b-th>
            <b-th>N° de licenças</b-th>
            <b-th>Status</b-th>
            <b-th>Ações</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr  
            v-for="(tr, indextr) in contracts" 
            :key="indextr">
            <b-td>
              {{ tr.company && tr.company.company_name }}
            </b-td>
            <b-td class="text-center">
              {{ tr.created_at | dateFormat }}
            </b-td>
            <b-td class="text-center">
              {{ tr.expiration_date | dateFormat }}
            </b-td>
            <b-td class="text-center">
              {{ tr.number_of_licences }}
            </b-td>
            <b-td v-if="tr.expiration_date <= nowDate" class="text-center">
              <b-badge
                variant="danger"
                class="badge-glow"
              >
                Expirado
              </b-badge>
            </b-td>
            <b-td v-else class="text-center">
              <b-badge
                variant="success"
                class="badge-glow"
              >
                Ativo
              </b-badge>
            </b-td>
            <b-td class="text-center">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                class="btn-icon rounded-circle"
                v-b-toggle.sidebar-create
                @click="updateDetail(tr.id)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                class="btn-icon rounded-circle"
                @click="showDetail(tr.id)"
                v-b-modal.modal-delete
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row" v-show="totalRows > 10">
        <div class="col-6 col-md-9"> 
          Exibindo {{ perPage }} de {{ totalRows }} registros
        </div>
        <div class="col-6 col-md">
          <b-pagination
            v-model="currentPage"
            class="text-center float-right"
            :per-page="perPage"
            :total-rows="totalRows"
            aria-controls="tableSchools"
            size="sm"
            @change="handlePageChange"
          />
        </div>
      </div>
      
    </b-overlay>
    </div>
    
    <div class="text-center" v-show="contracts.length == 0 && search == null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <no-data />
        <b-button variant="relief-primary" @click="addNew" v-b-toggle.sidebar-create size="sm">
          Nova Assinatura 
          <feather-icon icon="CalendarIcon" />
        </b-button>
      </b-overlay>
    </div>

    <create-edit :key="count" :dataProp="sidebarData"/>

    <!-- Modal Delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"  
      ref="modal-delete"
      centered
      title="Deletar"
      v-if="contractModel != null"
    >
      <b-card-text>
        <p>Você está preste a deletar esta assinatura do sistema:</p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
        >
          Concordo em excluir esta assinatura
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="!DeleteStatus"
            @click="deleteContract(contractModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEdit from "./CreateEdit.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    CreateEdit,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,

      DeleteStatus: false,
      contractModel: null,

      nowDate: null,

      sidebarData: {
        company: null,
        expiration_date: null,
        number_of_licences: null,
      },

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("contracts", ["contracts"]),
  },
  methods: {
    ...mapActions("contracts", ["update"]),

    fetchContracts(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("contracts/fetchContracts", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
          let dateNew = new Date();
          let dateDay = String(dateNew.getDate() + 1).padStart(2, '0');
          let dateMonth = String(dateNew.getMonth() + 1).padStart(2, '0');
          let dateYear = dateNew.getFullYear();
          this.nowDate =  dateYear + '-' + dateMonth + '-' + dateDay;
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchContracts(true, value)
    },

    showDetail(id){
      this.contractModel = this.$store.getters['contracts/getContractById'](id)
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deleteContract(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("contracts/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = null
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.fetchContracts()
          this.$refs['modal-delete'].hide()
        })
    },
    addNew() {
      this.sidebarData = {}; 
      this.count++;
    },
    updateDetail(id){
      this.updateModel = this.$store.getters['contracts/getContractById'](id);
      this.sidebarData.id = this.updateModel.id;
      this.sidebarData.company_id = this.updateModel.company_id;
      this.sidebarData.expiration_date = this.updateModel.expiration_date;
      this.sidebarData.number_of_licences = this.updateModel.number_of_licences;
      this.sidebarData.contract_items = this.updateModel.contract_items;
      this.count++
    },

    updateContract() {
        this.update({
        ...this.updateContract,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        }) 
        this.$nextTick(() => this.$refs.simpleRules.reset());  
      })
      .finally(() =>{
        this.$refs['sidebar-update'].hide()
        this.fetchContracts()
      });
    }
  },
  created() {
    // this.formatDate();
    this.fetchContracts();
  },
  watch: {
    perPage(){
      this.fetchContracts()
    },
    search(){
      this.currentPage = 1;
      this.fetchContracts(true, 1);
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>