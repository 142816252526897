<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      shadow
      right
      bg-variant="white"
      backdrop
      width="400px"
      ref="sidebar-create"
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar Assinatura' : 'Editar Assinatura'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-form method="POST">
            <validation-observer ref="simpleRules">
            <b-form-input class="hidden" name="_token" :value="csrf" />
            <b-form-group 
              label="* Empresa:" 
              label-for="i-company"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="empresa"
                rules="required"
              >
                <b-form-select
                  id="i-company"
                  v-model="dataContract.company_id"
                  :options="companies"
                  value-field="id"
                  text-field="company_name"
                  class="form-control"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="* Data de expiração:"
              label-for="i-icone"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="data de expiração"
                rules="required"
              >
                <b-form-input
                  id="i-expiration"
                  v-model="dataContract.expiration_date"
                  type="date"
                  placeholder="Ex: 31/12/2021"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="* N° de licenças:"
              label-for="i-number"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nº de licenças"
                rules="required"
              >
                <b-form-input
                  id="head"
                  v-model="dataContract.number_of_licences"
                  type="number"
                  step="1"
                  name="i-number"
                  class="form-control"
                  :onchange="dataContract.number_of_licences = Math.round(dataContract.number_of_licences)"
                  :state="errors.length > 0 ? false : null"
                />
                <!-- <b-form-spinbutton
                  id="sb-step"
                  v-model="dataContract.number_of_licences"
                  step="1"
                  min="1"
                  max="10000"
                  :state="errors.length > 0 ? false : null"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <hr />
            <!-- Divisão das licenças por livros -->
            <h6 class="text-muted pl-1 pr-1">ESCOLHER LIVROS</h6>
            <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
            <b-form enctype="multipart/form-data" method="post">
              <b-form-input class="hidden" name="_token" :value="csrf" />
              <b-form-group 
                label="* Selecione o livro:" 
                label-for="i-company"
                class="pl-1 pr-1"
              >
                  <b-form-select
                    id="i-company"
                    v-model="book_id"
                    :options="booksList"
                    value-field="id"
                    text-field="name"
                    class="form-control"
                  />
              </b-form-group>
              <b-form-group
                class="pl-1 pr-1"
                label="Quantidade:"
                label-for="i-amount"
              >
              <b-form-input
                type="number"
                v-model="amount"
              />
            </b-form-group >
              <b-row class="pl-1 pr-1">
                <b-col lg="12" sm="12" >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    block
                    @click="addBook"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-1"
                    />
                    <span class="align-middle">Adicionar livros</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="margin-right: 0rem !important;">
                <b-col lg="12" sm="12" >
                  <b-table-simple
                    striped
                    stacked="md"
                    class="mt-2 responsive"
                    id="tableBooks"
                  >
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th>Livro</b-th>
                        <b-th>Qtd</b-th>
                        <b-th>Ações</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr  
                        v-for="(tr, indextr) in dataContract.contract_items" 
                        :key="indextr">
                        <b-td>
                          {{ tr.book ? tr.book.name : 'Livro não encontrado' }}
                        </b-td>
                        <b-td>
                          {{ tr.amount }}
                        </b-td>
                        <b-td>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-dark"
                            class="btn-icon rounded-circle"
                            @click="remove_book(indextr)"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-form>
            </b-overlay>
            <hr />
            <b-row style="margin-right: 0rem !important;">
              <b-col lg="3" sm="6" class="ml-1 mr-1">
                <b-button
                  variant="relief-primary"
                  @click="storeContract((value = 1))"
                  :disabled="dataContract.company_id == null"
                >
                {{this.dataContract.id == null ? 'Salvar' : 'Editar'}}
                  <!-- Salvar -->
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="closeSidebar()"
                  block
                >
                  Fechar
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      icon: null,
      dataContract: {
        company_id: null,
        expiration_date: null,
        number_of_licences: 1,
        contract_items: [],
      },
      amount: null,
      book_id: null,

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("contracts", ["contracts"]),
    ...mapState("companies", ["companies"]),
    ...mapState("books", ["booksList"])
  },
  methods: {
    ...mapActions("contracts", ["store", "update"]),
    addBook(){
      let used_licenses = 0;
      if(this.dataContract.contract_items.length > 0){
        used_licenses = this.dataContract.contract_items.map(item => item.amount).reduce((prev, next) => parseInt(prev) + parseInt(next));
      }

      if((parseInt(used_licenses) + parseInt(this.amount)) > this.dataContract.number_of_licences){
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Número de licenças insuficientes.',
              variant: 'danger'
            }
          })
          return false;
      }

      let book = this.$store.getters['books/getShortBookById'](this.book_id)
      if(book && this.amount && this.amount > 0){
        let item = {
          book: book,
          amount: this.amount
        };
        this.dataContract.contract_items.push(item);
      }
    },
    remove_book(index){
      this.dataContract.contract_items.splice(index, 1);
    },

    fetchBooks(paginated = false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("books/fetchBooksShort", {
          paginate: paginated,
          page: page,
          type: 'student',
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchCompanies(paginated =false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("companies/fetchCompanies", {})
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchContracts(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("contracts/fetchContracts", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    storeContract(value) {
      this.$refs.simpleRules.validate().then(success => {
        if(success){
          this.isLoadingStore = true
          let used_licenses = 0
          if (this.dataContract.contract_items.length > 0) {
            used_licenses = this.dataContract.contract_items.map(item => item.amount).reduce((prev, next) => parseInt(prev) + parseInt(next));
          }
          if(this.dataContract.number_of_licences != used_licenses){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro de validação!',
                icon: 'AlertTriangleIcon',
                text: 'A soma das quantidades dos livros deve ser igual ao número de licenças.',
                variant: 'danger'
              }
            })
            this.isLoadingStore = false

            return 
          }

          this.dataContract.contract_items = this.dataContract.contract_items.map(item => {
            let items = {
              book_id: item.book.id,
              amount: item.amount
            }
            return items
          });
      
          if (Object.entries(this.dataProp).length === 0) { 
            this.store(this.dataContract).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.isLoadingStore = false

              this.dataContract.company_id =  null
              this.dataContract.expiration_date = null
              this.dataContract.number_of_licences = 1
              this.$nextTick(() => this.$refs.simpleRules.reset());

              this.closeSidebar()
            })
            .finally(() => {
              this.isLoadingStore = false
              this.fetchContracts();
            })
          } else { 
            this.update(this.dataContract).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })
              this.isLoadingStore = false

              this.dataContract.company_id =  null
              this.dataContract.expiration_date = null
              this.dataContract.number_of_licences = 1
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() => {
              this.fetchContracts();
              this.closeSidebar()
            })
          }
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
     
    },

    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      if (Object.entries(this.dataProp).length === 0) { 
        //this.initValues(); 
        //this.$validator.reset(); 
      } else { 
        let { id, company_id, expiration_date, number_of_licences, contract_items } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataContract.id = id;
        this.dataContract.company_id = company_id;
        this.dataContract.expiration_date = expiration_date;
        this.dataContract.number_of_licences = number_of_licences;
        this.dataContract.contract_items = contract_items;

        //this.initValues(); 
      } 
    }
  }
  },
  created() {
    this.fetchCompanies();
    this.fetchBooks();
  },
}
</script>